import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Resolver } from 'react-hook-form'
import { AnyObjectSchema, InferType } from 'yup'
import { TypedSchema } from 'yup/lib/util/types'

export type FormType<T extends TypedSchema> = InferType<T>

export const generateValidation = <T extends AnyObjectSchema>(
  schema: T,
): [schema: T, resolver: Resolver<FormType<T>>] => {
  const resolver = yupResolver<FormType<T>>(schema)
  return [schema, resolver]
}
