import { twMerge } from 'tailwind-merge'

import EyeIcon from '~shared/components/Icons/svg/eye.svg'

interface ShowPasswordButtonProps {
  show: boolean
  onClick: () => void
  testId?: string
  buttonClassName?: string
  showPasswordClassName?: string
  hidePasswordClassName?: string
}

export const ShowPasswordButton = ({
  show,
  onClick,
  testId,
  buttonClassName,
  showPasswordClassName,
  hidePasswordClassName,
}: ShowPasswordButtonProps) => (
  <button
    data-testid={testId || 'show-hide-password-eye-icon-button'}
    type='button'
    title={(!show ? 'Show' : 'Hide') + ' password'}
    className={twMerge(
      'pointer-events-auto',
      buttonClassName,
      show ? showPasswordClassName : hidePasswordClassName,
    )}
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    <EyeIcon
      className={twMerge(
        'transition-transform',
        show ? 'scale-110' : 'scale-90',
      )}
    />
  </button>
)

export default ShowPasswordButton
