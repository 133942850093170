import * as yup from 'yup'

import { generateValidation } from '~shared/validation/generator'

export type LoginForm = yup.InferType<typeof loginSchema>

export const [loginSchema, loginSchemaResolver] = generateValidation(
  yup.object({
    email: yup.string().email().required().label('Email'),
    password: yup.string().required().label('Password'),
    rememberMe: yup.bool().label('Remember me'),
  }),
)
