import { getRedirectRouteFromQueryParams, timeOfDay } from '@wise/utils'
import Link from 'next/link'
import Router from 'next/router'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '~shared/components/Buttons/Button/Button'
import Checkbox from '~shared/components/Input/Checkbox/Checkbox'
import TextInput from '~shared/components/Input/TextInput/TextInput'
import WithWiseLogo from '~shared/components/WithWiseLogo/WithWiseLogo'
import { useMode } from '~shared/hooks/useMode'
import { TRANSLATIONS_ENABLED, locales } from '~shared/i18n/i18n'
import { trackAnalyticsEvent } from '~shared/services/analytics/analytics'
import { useApollo } from '~shared/services/apollo/apollo'
import { Authenticator } from '~shared/services/firebase/auth/Authenticator'
import { log } from '~shared/utils/log'

import { LoginForm, loginSchemaResolver } from '@/users/validation/login'

import { AppPage } from '../types'

const LoginPage: AppPage = () => {
  const mode = useMode()
  const [loading, setLoading] = React.useState(false)
  const { t, i18n } = useTranslation()

  const { register, handleSubmit, errors, setError, watch } =
    useForm<LoginForm>({
      resolver: loginSchemaResolver,
    })

  const onSubmit = React.useCallback(
    async (loginForm: LoginForm) => {
      setLoading(true)
      try {
        const result = await Authenticator.signIn(
          loginForm.email,
          loginForm.password,
          loginForm.rememberMe,
        )
        if (!result) throw new Error('Could not login. Please try again later.')
        trackAnalyticsEvent('user_logged_in', {
          mode,
          firebase_id: result.id,
        })
        const url = getRedirectRouteFromQueryParams(Router.query) ?? '/'
        Router.push(url)
      } catch (error) {
        setError('email', {
          type: 'server',
          message: error instanceof Error ? error.message : 'Unknown error',
        })
        setLoading(false)
      }
    },
    [mode, setError],
  )

  const apolloClient = useApollo()

  React.useEffect(() => {
    log('apolloClient', 'Clearing Apollo Cache...')
    apolloClient.cache.reset()
  }, [apolloClient])

  return (
    <div className='flex h-full w-full flex-col md:flex-row'>
      <div className='flex flex-2 flex-col items-center bg-gradient-to-tr from-theme-dark via-theme to-theme-light p-0 py-24 text-font-white md:py-0 md:px-12'>
        <div className='flex h-full w-full max-w-screen-sm flex-col items-center justify-center'>
          <div className='flex w-full max-w-3/4 flex-1 items-center'>
            <div className='mb-16 w-32 md:mb-0'>
              <WithWiseLogo />
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex w-full max-w-3/4 flex-col'
          >
            <h2 className='mb-3 text-3xl font-bold'>{t(timeOfDay())}</h2>
            <p className='mb-6 text-lg font-light'>
              {t('login.provide_message')}
            </p>
            <TextInput
              ref={register}
              id='login__email'
              testId='login-email'
              name='email'
              type='email'
              placeholder={t('login.email') as string}
              isEmpty={!watch('email')}
              className='mb-6'
              autoComplete='off'
              error={errors.email}
            />
            <TextInput
              ref={register}
              id='login__password'
              testId='login-password'
              name='password'
              type='password'
              placeholder={t('login.password') as string}
              isEmpty={!watch('password')}
              className='mb-12'
              autoComplete='current-password'
              error={errors.password}
            />
            <div className='mb-9 flex flex-row justify-between px-3 text-sm'>
              <Checkbox
                ref={register}
                id='login__remember-me'
                testId='login-remember-me'
                name='rememberMe'
                label={t('login.remember_me') as string}
                error={errors.rememberMe}
              />
              <Link href='/forgot-password'>
                <a
                  data-testid='login-forgot-password-button'
                  className='h-auto text-yellow'
                >
                  {t('login.forgot_password')}
                </a>
              </Link>
            </div>
            <Button
              testId='login-button'
              loading={loading}
              type='submit'
              className='self-center rounded-full bg-white py-3 px-12 text-xl font-bold text-purple'
            >
              {t('login.login')}
            </Button>
            {TRANSLATIONS_ENABLED ? (
              <ul className='mt-9 flex items-center justify-center gap-x-2'>
                {locales.map((locale) => (
                  <li key={locale.code}>
                    <button
                      data-testid='change-language-button'
                      style={{
                        fontWeight:
                          i18n.resolvedLanguage === locale.code
                            ? 'bold'
                            : 'normal',
                      }}
                      type='submit'
                      onClick={() => i18n.changeLanguage(locale.code)}
                    >
                      {locale.flag}
                    </button>
                  </li>
                ))}
              </ul>
            ) : null}
          </form>
          <div className='flex-1' />
        </div>
      </div>
      <div className='flex flex-1 flex-col items-center justify-center bg-white p-12'>
        <div className='max-w-3/4'></div>
        <h3 className='mb-6 text-3xl font-bold text-black'>
          {t('login.welcome')}
        </h3>
        <p className='mb-6 max-w-3/4 text-center text-2xl font-light text-black'>
          {t('login.message_one')}
        </p>
        <p className='max-w-3/4 text-center text-2xl font-light text-black'>
          {t('login.message_two')}
        </p>
      </div>
    </div>
  )
}

export default React.memo(LoginPage)
