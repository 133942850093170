import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import { FieldError } from 'react-hook-form'

import ShowPasswordButton from '~shared/components/Input/ShowPasswordButton'

import FormError from '../../FormError/FormError'

interface Props {
  error?: FieldError
  placeholder?: string
  isEmpty?: boolean
  testId?: string
}

const TextInput = React.forwardRef<
  HTMLInputElement,
  Props & JSX.IntrinsicElements['input']
>(
  (
    {
      className,
      error,
      isEmpty = true,
      placeholder,
      testId: rawTestId,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)

    const filled = React.useMemo(() => focus || !isEmpty, [focus, isEmpty])

    const { testId, errorTestId } = React.useMemo(() => {
      const raw = rawTestId || props.id || undefined
      if (!raw) return { testId: undefined, errorTestId: undefined }
      return { testId: kebabCase(raw), errorTestId: `${kebabCase(raw)}-error` }
    }, [props.id, rawTestId])

    return (
      <div className={cx(className)}>
        <div
          className={cx(
            'relative w-full transform-gpu rounded-tr-sm rounded-tl-sm border-b-2 border-white pt-3 transition-all',
            filled && 'bg-darken shadow-md',
          )}
        >
          <div
            className='w-full'
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            <input
              {...props}
              className='w-[97%] bg-transparent p-4 text-base font-bold focus:outline-none'
              ref={ref}
              data-testid={testId}
              type={
                props.type !== 'password'
                  ? props.type
                  : showPassword
                  ? 'text'
                  : 'password'
              }
            />
            {props.type === 'password' ? (
              <div className='pointer-events-none absolute bottom-0 top-3 right-3 flex select-none items-center justify-center'>
                <ShowPasswordButton
                  show={showPassword}
                  onClick={() => setShowPassword(!showPassword)}
                  showPasswordClassName='text-white'
                  hidePasswordClassName='text-white/40'
                />
              </div>
            ) : null}
          </div>
          <label
            htmlFor={props.id}
            className={cx(
              'absolute left-4 top-4 bottom-4 flex origin-left transform-gpu cursor-auto items-center font-light transition-transform duration-200',
              filled && '-translate-y-6 scale-75',
            )}
          >
            {placeholder}
          </label>
        </div>
        <FormError testId={errorTestId}>{error?.message}</FormError>
      </div>
    )
  },
)

TextInput.displayName = 'TextInput'

export default React.memo(TextInput)
